<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What topics should be included on the perfect CHEMISTRY LAB syllabus? Check all that apply.
      </p>

      <p v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ17',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {text: 'Purpose of the course', value: 'one'},
        {text: 'Course objectives', value: 'two'},
        {text: 'Class expectations', value: 'three'},
        {text: 'Scoring policy', value: 'four'},
        {text: 'Kinds of assignments', value: 'five'},
        {text: 'Technology clause', value: 'six'},
        {text: 'Tech support directions', value: 'seven'},
        {text: 'How to calculate your grade', value: 'eight'},
        {text: 'Safety rules', value: 'nine'},
        {text: 'Attendance policy', value: 'ten'},
        {text: 'Graded work appeal process', value: 'eleven'},
        {text: 'Glassware breakage policy', value: 'twelve'},
        {text: 'Withdrawal policy', value: 'thirteen'},
        {text: 'Academic honesty policy', value: 'fourteen'},
        {text: 'How to get access to course materials', value: 'fifteen'},
        {text: 'Cell phone / smart watch policy', value: 'sixteen'},
        {text: 'Disability accommodations policy', value: 'seventeen'},
        {text: 'Equity and inclusion policy', value: 'eighteen'},
        {text: 'Counseling services available', value: 'nineteen'},
        {text: 'Sexual misconduct services available', value: 'twenty'},
        {text: 'Stalking and violence policy', value: 'twentyOne'},
        {text: 'Flexibility clause', value: 'twentyTwo'},
        {text: 'Copyright policy', value: 'twentyThree'},
        {text: 'Schedule of labs', value: 'twentyFour'},
        {text: 'Schedule of assignments', value: 'twentyFive'},
        {text: 'Extra credit opportunities', value: 'twentySix'},
        {text: 'SI or tutoring services available', value: 'twentySeven'},
        {text: 'Instructor contact information', value: 'twentyEight'},
        {text: 'TA contact information', value: 'twentyNine'},
      ],
    };
  },
};
</script>
<style scoped></style>
